const get = require('lodash/get');

class Validator {
  get rules() {
    return {};
  }

  constructor() {
    this.errors = {};
  }

  passesValidator(validator, value) {
    if (validator instanceof RegExp) {
      return validator.test(value);
    } else if (validator instanceof Function) {
      return validator(value);
    }
  }

  validate(prop, value) {
    delete this.errors[prop];

    const { validators, _, label } = this.rules[prop];

    for (const validator of Object.values(validators)) {
      if (this.passesValidator(validator, value) === false) {          
        this.errors[prop] = `${label} måste anges`;
      }
    }

    return this.errors[prop] === undefined;
  }

  passes(data) {
    for (const field of Object.keys(this.rules)) {
      if (! this.validate(field, get(data, field))) {
        return false;
      }
    }
    return true;
  }
}

module.exports = Validator;