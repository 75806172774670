import VueRecaptcha from 'vue-recaptcha';
import fetchConfig from './fetchConfig';
import formMixin from './formMixin';
import initState from './facilityRequestState';
import validator from './facilityRequestValidator';
import ElButton from './elButton';

const facilityRequestForm = {
  delimiters: ['<%', '%>'],
  components: { VueRecaptcha, ElButton },
  mixins: [formMixin],
  data: initState(),
  computed: {
    validator() {
      return validator;
    }
  },
  methods: {
    onExpired() {
      this.loading = false;
    },
    submitFacilityRequst() {
      if (! this.loading) {
        this.touchForm();
        this.validateForm()
        if (this.formValid) {
          this.loading = true;
          this.$refs.recaptcha.execute();
        }
      }
    },
    sendFacilityRequest(recaptchaResponse) {
      fetch('/lokal-forfrogan', {
        ...fetchConfig(),
        method: 'POST',
        body: JSON.stringify({
          recaptchaResponse,
          ...this.form
        })
      })
        .then(body => body.json())
        .then(json => {
          Object.assign(this.$data, initState());
          alert('Din förfrågan har skickats!');
          this.$refs.recaptcha.reset();
        })
        .catch(err => {
          alert('Något gick fel testa igen!');
          this.loading = false;
        })
    }
  }
};

export default facilityRequestForm;