export default function initState() {
  return {
    loading: false,
    errors: {},
    facilityTypes: [
      'Kontor',
      'Lager/Logistik',
      'Industri',
      'Förrådshotell',
      'Övrigt'
    ],
    touched: {
      name: false,
      company: false,
      email: false,
      telephone: false,
      facilityType: false,
      spaceSqm: false
    },
    valid: {
      name: false,
      company: false,
      email: false,
      telephone: false,
      facilityType: true,
      spaceSqm: true
    },
    form: {
      name: '',
      company: '',
      email: '',
      telephone: '',
      facilityType: 'Kontor',
      spaceSqm: 1
    }
  };
}