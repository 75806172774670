const isEmail = require('validator/lib/isEmail');
const isNumber = require('lodash/isNumber');
const isString = require('lodash/isString');
const isFilledString = require('./helpers/isFilledString');
const Validator = require('./validator');

class FacilityRequestValidator extends Validator {
  get rules() {
    return {
      name: {
        label: 'Kontaktperson',
        validators: [isString, isFilledString]
      }, 
      company: {
        label: 'Företag',
        validators: [isString, isFilledString]
      }, 
      email: {
        label: 'Email',
        validators: [isString, isEmail]
      }, 
      telephone: {
        label: 'Telefonnummer',
        validators: [/\+?(46|0)[0-9]{2}/]
      }, 
      facilityType: {
        label: 'Lokaltyp',
        validators: [isString, isFilledString]
      }, 
      spaceSqm: {
        label: 'Lokalyta',
        validators: [isNumber, value => value >= 0]
      }
    };
  }
};

module.exports = new FacilityRequestValidator();