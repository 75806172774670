export default {
  computed: {
    formValid() {
      return Object.values(this.valid).every(v => v === true)
    },
    validator() {
      console.warn('Validator must be supplied');
    },
    formTouched() {
      return Object.values(this.touched).some(v => v === true)
    }
  },
  methods: {
    touchField({ target: { name } }) {
      this.touched[name] = true;
    },
    validateField(name, value) {
      this.valid[name] = this.validator.validate(name, value);
      this.errors = this.validator.errors;
    },
    inputNumberField({ target: { name, value }}) {
      this.inputField({ target: { name, value: Number(value) }});
    },
    inputField({ target: { name, value } }) {
      this.form[name] = value;
      this.validateField(name, value);
      this.touched[name] = true;
    },
    touchForm() {
      for (const field of Object.keys(this.touched)) {
        this.touched[field] = true;
      }
    },
    validateForm() {
      for (const field of Object.keys(this.form)) {
        this.validateField(field, this.form[field]);
      }
    }
  }
};