export default function initState() {
  return {
    loading: false,
    errors: {},
    touched: {
      name: false,
      company: false,
      address: false,
      email: false,
      telephone: false,
      description: false
    },
    valid: {
      name: false,
      company: false,
      address: false,
      email: false,
      telephone: false,
      description: false
    },
    form: {
      name: '',
      company: '',
      address: '',
      email: '',
      telephone: '',
      description: ''
    }
  };
}