import VueRecaptcha from 'vue-recaptcha';
import fetchConfig from './fetchConfig';
import formMixin from './formMixin';
import initState from './faultReportState';
import validator from './faultReportValidator';
import ElButton from './elButton';

const facilityRequestForm = {
  components: { VueRecaptcha, ElButton },
  mixins: [formMixin],
  data: initState(),
  computed: {
    validator() {
      return validator;
    }
  },
  methods: {
    onExpired() {
      this.loading = false;
    },
    submit() {
      if (! this.loading) {
        this.touchForm();
        this.validateForm()
        if (this.formValid) {
          this.loading = true;
          this.$refs.recaptcha.execute();
        }
      }
    },
    send(recaptchaResponse) {
      fetch('/felanmalan', {
        ...fetchConfig(),
        method: 'POST',
        body: JSON.stringify({
          recaptchaResponse,
          ...this.form
        })
      })
        .then(body => body.json())
        .then(json => {
          Object.assign(this.$data, initState());
          alert('Din felanmälan har skickats!');
          this.$refs.recaptcha.reset();
        })
        .catch(err => {
          alert('Något gick fel testa igen!');
          this.loading = false;
        })
    }
  }
};

export default facilityRequestForm;