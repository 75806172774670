export default {
  template: `<button type="submit" class="btn btn-default" :class="['btn-' + size, { 'disabled': disabled, 'btn-block': block }]" :disabled="disabled && !loading">
    <span v-if="loading">Laddar <i class="fa fa-spinner fa-spinner--loading"></i></span>
    <span v-else><slot></slot></span>
  </button>`,
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      require: false,
      default: 'md'
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};