import '../assets/scss/main.scss';
import $ from 'jquery';
import Vue from 'vue/dist/vue.esm.js';
import facilityRequestForm from './facilityRequestForm';
import faultReportForm from './faultReportForm';
window.jQuery = $;
window.csrfToken = document.querySelector('[name=csrf-token]').content;

require('bootstrap-sass/assets/javascripts/bootstrap/transition');
require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1400
}

function autoHeight(elements) {
  for (const el of elements) {
    el.style.height = 'auto';
    el.style['min-height'] = '';
  }

  if (window.innerWidth < breakpoints.sm) return;

  let minHeight = elements[0].clientHeight;
  let maxHeight = minHeight;
  
  for (const el of elements) {
    if (el.clientHeight > maxHeight) {
      maxHeight = el.clientHeight;
    } else {
      minHeight = el.clientHeight;
    }
  }

  if (minHeight !== maxHeight) {
    for (const el of elements) {
      el.style['min-height'] = `${maxHeight}px`;
    }
  }

  // console.log(window.location.pathname, minHeight, maxHeight);
}

if (window.location.pathname === '/') {
  new Vue({
    el: '#facilityRequestForm',
    delimiters: ['<%', '%>'],
    ...facilityRequestForm
  });
}
else if (window.location.pathname === '/felanmalan') {
  new Vue({
    el: '#faultReportForm',
    delimiters: ['<%', '%>'],
    ...faultReportForm
  });
}
else if (['/fastigheter', '/lediga-lokaler'].includes(window.location.pathname)) {
  const elements = document.getElementsByClassName('card__body');
  window.addEventListener('load', () => autoHeight(elements));
  window.addEventListener('resize', () => autoHeight(elements));
}

// let lastTopOffset = 0;
// const offsetMargin = 30;
// function handlerScrollMain() {
//   const topOffset = $(this).scrollTop();
//   if (topOffset > lastTopOffset && topOffset > offsetMargin) {
//     $('.navbar').addClass('fadeOutUp');
//     $('.navbar').removeClass('fadeInDown');
//   } else if (topOffset < lastTopOffset && topOffset <= offsetMargin) {
//     $('.navbar').addClass('fadeInDown');
//     $('.navbar').removeClass('fadeOutUp');
//   }
//   lastTopOffset = topOffset;
// }

// function handlerScrollAnimations(e) {
//   const aboutUsSection = $('#about-us');
//   if (Helpers.isInViewport(aboutUsSection)) {
//     aboutUsSection.find('.animated').removeClass('hide');
//     aboutUsSection.find('.animated').addClass('fadeInDown');
//     $(this).unbind(e);
//   }
// }

// $(window).on('scroll.main', handlerScrollMain);
// $(window).on('scroll.animations', handlerScrollAnimations);
// $(function() {
//   $('.column__footer').matchHeight();
// });

